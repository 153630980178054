<template>
  <div class="viewFeedMechanism">
    <div class="header">
      <div>
        <span class="supplierName">{{sponsorName}}</span>
        项目
      </div>
      <el-button type="primary" @click="exportFeed">导出</el-button>
    </div>
    <el-table
      class="ly_table"
      :data="viewFeedMechanismTable"
      :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column
          prop="activityName"
          label="活动名称"
          align="center"
          width="230"
        >
        </el-table-column>
        <template slot="empty">
          <img
            src="@/assets/img/table.png"
            alt="没有数据"
            srcset=""
          />
        </template>
        <el-table-column prop="activityHost" label="主办方" align="center" />
        <el-table-column prop="activityType" label="活动类别" align="center" />
        <el-table-column label="活动时间" align="center" width="300">
          <template v-slot="{ row }">
            {{ row.beginTime }}-{{ row.endTime ? "-" + row.endTime : "" }}
          </template>
        </el-table-column>
        <el-table-column label="所在地区" align="center">
          <template v-slot="{ row }">
            {{ row.activityCountry ? row.activityCountry + "-" : "" }}
            {{ row.activityProvince ? row.activityProvince + "-" : "" }}
            {{ row.activityCity ? row.activityCity : "" }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" />
        <el-table-column prop="createName" label="创建人" align="center" />
      <el-table-column label="操作" width="150" align="center">
        <template v-slot="{row}">
          <button-table @buttonClick="viewItem(row)" content="查看" icon="jr-iconjr-icon-eye" />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="queryInfo.pageNum"
      :page-sizes="[3, 5, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { pagination } from '@/mixins/pagination'
import { getActivityAndOrganizerList } from '@/api/activity.js'
import ButtonTable from '@/components/ButtonTable.vue'
import { exportData } from '@/utils/tool'
export default {
  name: 'ViewFeedMechanism',

  components: {
    ButtonTable,
  },

  mixins: [pagination],

  data() {
    return {
      name:"",
      viewFeedMechanismTable: [],
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
      },
      ids: [],
    }
  },
  computed: {
    sponsorId() {
      return Number(this.$route.query.id)
    },
    sponsorName() {
      return this.$route.query.name
    },
  },
  created() {
    this.name = this.sponsorName
    this.search()
  },

  methods: {
    async search() {
      Object.assign(this.queryInfo, { id: this.sponsorId })

      const res = await getActivityAndOrganizerList(this.queryInfo)

      if (res.code === 200) {
        this.viewFeedMechanismTable = res.data.list
      }

      this.total = res.data.total
    },
    handleSelectionChange(val) {
      this.ids = val.map((item) => {
        return item.id
      })
    },
    viewItem(row) {
      const data = row.id.toString()
      this.$router.push({
        name: 'ActiveDataInfo',
        query: {
          personType: "1",
          id: data,
          demandType: "1",
          posterType: row.activityPosterUrl
        },
      })
    },
    exportFeed() {
      if (this.ids.length) {
        const params = {
          idList: this.ids
        }

        exportData(
          params,
          '/organizer/exportExcel',
          'application/msexcel',
          this.search,
          '主办方活动数据.xls'
        )
      } else {
        this.$message.warning('请选择要导出的数据')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.viewFeedMechanism {
  background: #fff;
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    .supplierName {
      color: #1e9fff;
      font-size: 18px;
      margin-right: 5px;
    }
  }
}
</style>
